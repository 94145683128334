@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  color-scheme: dark;
}

body {
  color: white;
  background: black;
  scroll-behavior: smooth;
}
